import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { collection, collectionData, DocumentData, DocumentSnapshot, Firestore, getDoc, setDoc, doc, where, Query, query } from '@angular/fire/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { COLLECTION } from 'src/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private firestore: Firestore,
  ) { }

  getCollection(name: string): Observable<DocumentData[]> {
    return collectionData(collection(this.firestore, name));
  }

  getDocument(name: string, id: string): Promise<DocumentSnapshot<DocumentData>> {
    return getDoc(doc(this.firestore, name, id))
  }

  async getDocumentsWhere(name: string, options: any[] = []): Promise<DocumentData[]> {
    let q: any = null;
    if (options.length >= 2) {
      q = query(collection(this.firestore, name),
        where(options[0].field, options[0].operator, options[0].value),
        where(options[1].field, options[1].operator, options[1].value));
    } else if (options.length === 1) {
      q = query(collection(this.firestore, name),
        where(options[0].field, options[0].operator, options[0].value));
    } else {
      q = query(collection(this.firestore, name));
    }

    return await firstValueFrom(collectionData(q, { idField: 'id' }));
  }

  async setDocument(name: string, id: string, data: any, options: any = {}) {
    return await setDoc(doc(this.firestore, name, id), data, options);
  };


  async setUserData(userData: User, params: any = null) {
    try {
      const data = {
        uid: userData.uid,
        email: userData.email,
        displayName: userData.displayName,
        photoURL: userData.photoURL,
        emailVerified: userData.emailVerified,
        ...params
      };
      await setDoc(doc(this.firestore, COLLECTION.USERS, userData.uid), data, { merge: true });
      const user = await this.getDocument(COLLECTION.USERS, userData.uid);

      return Promise.resolve(user);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

