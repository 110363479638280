<div class="vex-user-menu">

  <!--
  <a (click)="close()" [routerLink]="['/apps/social']" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">Your Profile</div>
  </a>
  -->

  <a (click)="close()" [routerLink]="['/']" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:dashboard"></mat-icon>
    <div class="vex-user-menu-item__label">Dashboard</div>
    <div class="vex-user-menu-item__badge">NEW</div>
  </a>

  <a (click)="close()" [routerLink]="['/settings/account']" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="vex-user-menu-item__label">Account Settings</div>
  </a>

  <div class="border-b border-divider mx-4"></div>

  <!--
  <div class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
    <div class="vex-user-menu-item__label">Switch Account</div>
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:chevron_right"></mat-icon>
  </div>
  -->

  <a (click)="signOut()" [routerLink]="['/auth/login']" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="vex-user-menu-item__label">Sign Out</div>
  </a>
</div>
