import { Injectable } from '@angular/core';

import {
    Auth,
    signOut,
    signInWithPopup,
    user,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    updateProfile,
    sendEmailVerification,
    sendPasswordResetEmail,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    GithubAuthProvider,
    OAuthProvider,
    linkWithPopup,
    unlink,
    updateEmail,
    updatePassword,
    User,
    reauthenticateWithPopup,
    authState,
    onAuthStateChanged,
    getAuth,
    onIdTokenChanged,
} from "@angular/fire/auth";

import {
    addDoc,
    setDoc,
    collection,
    doc,
    docData,
    DocumentReference,
    CollectionReference,
    Firestore,
    getFirestore,
    onSnapshot,
    query,
    where,
    Unsubscribe,
    Query,
    DocumentData,
    collectionData,
    collectionChanges,
    docSnapshots,
    getDocs,
} from "@angular/fire/firestore";

import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpErrorResponse,
} from "@angular/common/http";
import axios, { AxiosResponse } from "axios";
import { Md5 } from "ts-md5/dist/md5";
import * as _ from "lodash";

import { environment } from "src/environments/environment";
import { FirestoreService } from "src/services/firebase/firestore.service";

import {
    BehaviorSubject,
    firstValueFrom,
    Observable,
    of,
    Subscription,
    switchMap,
} from "rxjs";
import {
    browserLocalPersistence,
    browserSessionPersistence,
    deleteUser,
    setPersistence,
    UserCredential,
} from "firebase/auth";
import { Router } from "@angular/router";

// import { LoginData } from "src/interfaces/ILoginData";
import { UserData } from "src/interfaces/IUserData";
import { IUser } from "src/models/IUser";

import { COLLECTION } from "src/shared/constants";

// const IS_DEBUG = !environment.production;
const BASE_URL = environment.cloudUrl + "/";
const instance = axios.create({
    baseURL: BASE_URL,
    // baseURL: 'https://webhook.site/7877e607-24eb-44e1-9c7e-771537348d2a',
    timeout: 15000,
});

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    END_POINT: string;
    CLOUD_URL: string;
    authUser: User;
    token: string = "";
    private user: IUser;
    public user$ = new BehaviorSubject<IUser>(null);
    public isLoggedIn = false;
    public isLoggedIn$ = new BehaviorSubject<boolean>(false);
    public authMessage = new BehaviorSubject<string>("");

    constructor(
        // private afs: Firestore,
        private auth: Auth,
        // private http: HttpClient,
        private firestoreService: FirestoreService,
        private router: Router
    ) {
        this.END_POINT = environment.apiUrl;
        this.CLOUD_URL = environment.cloudUrl;

        // Get the user logged into firebase but transformed to IUser and ICompany
        onIdTokenChanged(auth, async (authUser) => {
            // Only allow login if user is verified
            console.log("authUser: ", authUser);
            if (!authUser) {
                console.log("No authUser");
                // this.authUser = null;
                // localStorage.removeItem('user');
                localStorage.removeItem("token");
                this.isLoggedIn = false;
                this.isLoggedIn$.next(false);
                return Promise.resolve(false);
            } else if (authUser && !authUser.emailVerified) {
                console.log("User is not verified");
                /*
                this.authMessage.next("Please verify your email address");
                this.authMessage.next("");
                console.log("emailVerified:", authUser.emailVerified);
                console.log("Signing out user");
                // this.authUser = null;
                // localStorage.removeItem('user');
                localStorage.removeItem("token");
                await signOut(auth);
                console.log("User signed out");
                this.isLoggedIn = false;
                this.isLoggedIn$.next(false);
                return Promise.resolve(false);
                */
            }

            console.warn("AUTH CHANGE", authUser);
            this.authUser = authUser;
            this.token = await authUser?.getIdToken() || "";
            this.isLoggedIn = true;
            this.isLoggedIn$.next(true);
            console.log("Load User:", authUser);
            const newUser = await this.loadUser(authUser);
            console.log("NewUser:", newUser);
            this.user$.next(newUser);


            this.router.navigate(['/']);
            return Promise.resolve(true);
        });
    }

    async loadUser(authUser: User) {
        if (authUser) {
            const user = await this.getUserFirebase(authUser);
            if (user) {
                user.token = await authUser.getIdToken();
                localStorage.setItem('user', JSON.stringify(user));
                this.user = user;
                this.user$.next(user);
            } else {
                localStorage.setItem('user', 'null');
                this.user = null;
                this.user$.next(user);
            }
        }
        return this.user;
    }

    getToken() {
        try {
            return this.authUser.getIdToken()
                .then((token: string) => { this.user.token = token; return token; });
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async register(params: any = null) {
        try {
            const { email, password } = params;
            return createUserWithEmailAndPassword(this.auth, email, password)
                .then(async (value: UserCredential) => {
                    // Send email verification
                    await sendEmailVerification(value.user)
                        .then(() => {
                            console.log('Verification email sent!');
                        });

                    if (params) {
                        params = this.getDefaultValues(params, value.user);
                    }
                    await updateProfile(value.user, params).then(async () => {
                        const data = Object.assign({}, value.user, params);
                        await this.updateUserData(data);
                    });
                    console.log("Done with user registration");
                    return "Verification email sent!";
                });
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                console.log('That email address is already in use!');
            }

            console.error('Something is wrong:', error.message);
            throw error;
        }
    }

    async login(params: any = null) {
        try {
            const { email, password } = params;

            // browserLocalPersistence save correctly in local, includes f5 reload
            const userCredentials = await setPersistence(getAuth(), browserLocalPersistence)
                .then(async () => {
                    console.log("@@ Persistence set to browserLocalPersistence");

                    const signInResult = await signInWithEmailAndPassword(this.auth, email, password)
                        .then(
                            async (userCredentials) => {
                                console.log("@@ User credentials:  ", userCredentials);

                                await this.firestoreService.setUserData(userCredentials.user, null);

                                console.log("** User data is now set");
                                return userCredentials;
                            }
                        );
                    console.log("@@ Sign in result: ", signInResult);

                    return signInResult;
                });

            return Promise.resolve(userCredentials);
        } catch (error) {
            console.error('Something is wrong:', error.message);
            throw error;
        }
    }

    private getDefaultValues(params: any, value: User = null) {
        // Update user profile
        params.firstName = !params.firstName ? '' : params.firstName.trim();
        params.lastName = !params.lastName ? '' : params.lastName.trim();

        if (value) {
            params.displayName = !value.displayName ? (params.firstName + ' ' + params.lastName) : value.displayName.trim();
            params.photoURL = !params.photoURL ? value.photoURL : params.photoURL.trim();
            params.email = !params.email ? value.email : params.email.trim();
            params.phoneNumber = !params.phoneNumber ? value.phoneNumber : params.phoneNumber.trim();
            params.emailVerified = !params.emailVerified ? false : params.emailVerified;
            params.id = !params.id ? value.uid : params.id.trim();
            params.providerId = !params.providerId ? value.providerId : params.providerId.trim();
            params.disabled = !params.disabled ? false : params.disabled;
        }

        params.createdAt = !params.createdAt ? new Date() : params.createdAt;
        params.createdBy = !params.createdBy ? params.id : params.createdBy;
        params.updatedAt = !params.updatedAt ? new Date() : params.updatedAt;
        params.updatedBy = !params.updatedBy ? params.id : params.updatedBy;
        params.deletedAt = !params.deletedAt ? null : params.deletedAt;
        params.deletedBy = !params.deletedBy ? null : params.deletedBy;

        params.lastSignInTime = !params.lastSignInTime ? new Date() : params.lastSignInTime;
        // params.customClaims = !params.customClaims ? '' : params.customClaims;
        // params.tenantId = !params.tenantId ? '' : params.tenantId;
        // params.metadata = !params.metadata ? '' : params.metadata;
        // params.providerData = !params.providerData ? '' : params.providerData;

        params.id = !params.id ? '' : params.id;
        params.firstNameLcase = !params.firstNameLcase ? '' : params.firstName.toLowerCase().trim();
        params.lastNameLcase = !params.lastNameLcase ? '' : params.lastNameLcase.toLowerCase().trim();
        params.displayName = !params.displayName ? '' : params.displayName.toLowerCase().trim();

        params.dateOfBirth = !params.dateOfBirth ? '' : params.dateOfBirth;
        params.phoneExt = !params.phoneExt ? '' : params.phoneExt;
        params.countryCode = !params.countryCode ? '' : params.countryCode;
        params.status = !params.status ? '' : params.status;
        params.type = !params.type ? '' : params.type;
        params.token = !params.token ? '' : params.token;

        return params;
    }

    private async getUserFirebase(user: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (user == null) {

                console.log("User not logged in.");

                reject(null);
            }

            const snapshot = await this.firestoreService.getDocument(COLLECTION.USERS, user.uid);
            if (snapshot.exists()) {
                resolve(snapshot.data());
            } else {
                reject(null);
            }
        });
    }

    async createUser(userData: UserData) {
        try {
            const url = BASE_URL + "userAdd";
            const config = {
                url: url,
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
            };
            const userRes = await axios.post(url, JSON.stringify(userData), config);

            return Promise.resolve(userRes.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                throw error;
            } else {
                console.log("unexpected error: ", error);
                throw error;
            }
        }
    }

    async updateUserData(userData: UserData) {
        try {
            const url = BASE_URL + "updateUserData";
            const config = {
                url: url,
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
            };
            const userRes = await axios.post(url, JSON.stringify(userData), config);

            return Promise.resolve(userRes.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                throw error;
            } else {
                console.log("unexpected error: ", error);
                throw error;
            }
        }
    }

    async deleteUser(userId: string) {
        // return this.fs.deleteDocument(COLLECTION.USERS, userId);
        return;
    }

    async removeUser(userId: string, companyId: string, agencyId: string) {
        try {
            const userData = {
                userId: userId,
                companyId: companyId,
                agencyId: agencyId,
            };
            const url = BASE_URL + "userRemove";
            const config = {
                url: url,
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
            };
            const userRes = await axios.post(url, JSON.stringify(userData), config);

            return Promise.resolve(userRes.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                throw error;
            } else {
                console.log("unexpected error: ", error);
                throw error;
            }
        }
    }

    // Returns true when user is looged in and email is verified
    // get isLoggedIn(): boolean {
    //     const user = JSON.parse(localStorage.getItem('user')!);
    //     return user !== null && user.emailVerified !== false ? true : false;
    // }

    async logout() {
        return signOut(this.auth).then(() => {
            this.authUser = null;
            localStorage.removeItem('user');
            localStorage.removeItem("token");
            this.router.navigate(['/auth/login']);
        });
    }

    async resetPassword(email: string) {
        return sendPasswordResetEmail(this.auth, email);
    }

    get userId(): string {
        if (typeof this.user === "undefined" || this.user === null) {
            return "";
        } else {
            return this.user.id;
        }
    }

    get userName(): string {
        if (typeof this.user === "undefined" || this.user === null) {
            return "";
        } else {
            return this.user.name;
        }
    }

    get userEmail(): string {
        if (typeof this.user === "undefined" || this.user === null) {
            return "";
        } else {
            return this.user.email;
        }
    }

    get userPhotoURL(): string {
        if (typeof this.user === "undefined" || this.user === null) {
            return "";
        } else {
            return this.user.photoURL;
        }
    }

    get userPhone(): string {
        if (typeof this.user === "undefined" || this.user === null) {
            return "";
        } else {
            return this.user.phoneNumber;
        }
    }

    // Auth logic to run auth providers
    async authLogin(provider: any) {
        try {
            return await signInWithPopup(this.auth, provider)
                .then((value) => {
                    this.firestoreService.setUserData(value.user, null);
                    this.router.navigate(['/']);
                });
        } catch (error) {
            console.error(error);
            window.alert(error.message);
        }
    }

    async loginWithGoogle() {
        // return await signInWithPopup(this.auth, new GoogleAuthProvider());
        return await this.authLogin(new GoogleAuthProvider()).then(() => { });
    }

    async loginWithGithub() {
        return await this.authLogin(new GithubAuthProvider()).then(() => { });
    }
}
