import { Injectable } from '@angular/core';
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { environment } from "src/environments/environment";
import { AuthService } from '../auth/auth.service';

const BASE_URL = environment.cloudUrl + "/";
const instance = axios.create({
    baseURL: BASE_URL,
    // baseURL: 'https://webhook.site/7877e607-24eb-44e1-9c7e-771537348d2a',
    timeout: 15000,
});

@Injectable({
    providedIn: 'root'
})
export class PhoneValidationService {

    constructor(
        private authService: AuthService,
    ) { }

    async phoneLookup(params: any = null) {
        try {

            const phoneNumber = params.phoneNumber;
            const country = params.country;
            const strictness = params.strictness;
            const locationId = "phonerep";
            const apikey = this.authService.userId;

            // /lookupPhone?phone=${encodeURIComponent(phoneInfo.id.trim())}&locationId=${prCompanyId}&apikey=${prApiKey}`;
            const url = BASE_URL + `lookupPhone?phone${encodeURIComponent(phoneNumber)}&locationId=${locationId}&apikey=${apikey}`;
            const config: AxiosRequestConfig = {
                method: "POST",
                url: url,
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    // "Authorization": "Bearer " + await this.authService.getToken()
                },
                data: {
                    phone: phoneNumber,
                    locationId: locationId,
                    apikey: apikey,
                    country: country,
                    strictness: strictness,
                },
            };
            const userRes = await axios(config);

            return Promise.resolve(userRes.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error);
                throw Error("unexpected error");
            } else {
                console.log(error);
                throw error;
            }
        }
    }
}
