// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAo6MVpcNdeLgOnZyqrEkA5V_Bf-e7inUs",
    authDomain: "phonerep-dev.firebaseapp.com",
    projectId: "phonerep-dev",
    storageBucket: "phonerep-dev.appspot.com",
    messagingSenderId: "406620982130",
    appId: "1:406620982130:web:5c1a1c5e92bbac82cd2beb",
    measurementId: "G-VCEL3RTXT3"
  },
  cloudUrl: "https://us-central1-phonerep-dev.cloudfunctions.net",
  apiUrl: "https://api-dev.phonerep.net",
  jsUrl: "https://firebasestorage.googleapis.com/v0/b/phonerep-dev.appspot.com/o/assets%2Fjs%2Fphonerep-dev-v0.0.4.min.js?alt=media&token=40ec8cd2-e58c-4f8e-9791-395811aca8e2",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
