import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "src/services/auth/auth.service";
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private authService: AuthService,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isLoggedIn) {
            return true;
        }

        if (localStorage.getItem('user')) {
            return true;
        }

        // navigate to login page
        this._router.navigate(['/auth/login']);
        // you can save redirect url so after authing we can move them back to the page they requested
        return false;
    }
}
